import { createSlice } from '@reduxjs/toolkit';
import data from './contents';

const AWS = process.env.REACT_APP_AWS_IMG;
const LOCAL = process.env.REACT_APP_LOCAL_IMG;

export const imgSlice = createSlice({
  name: 'img',
  initialState: {
    img: AWS,
  },
  reducers: {},
});

export default imgSlice.reducer;
export const selectImg = (state: any) => state.img.img;
