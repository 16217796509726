import { selectImg } from "redux/img";
import { useSelector } from "react-redux";
import "./middleHeader.scss";

const MiddleHeader = ({
  img,
  classes,
}: {
  img: string;
  size?: number;
  classes?: string;
}) => {
  const reduxImg: string = useSelector(selectImg);

  return (
    <div id="middle_header" className={`basic-flex ${classes} content`}>
      <div className="middle_header_line"></div>
      <img className="middle_header_img" src={`${reduxImg}${img}.png`} alt="" />
      <div className="middle_header_line"></div>
    </div>
  );
};

export default MiddleHeader;
