import { useState, useEffect } from "react";
import "./popup.scss";

const PopUp = () => {
  const [popup, setPopup]: any = useState({});
  const POPUP = "popup";

  const noToday = () => {
    const DAYTOSEC = 60 * 60 * 24 * 1000;
    const expire = Date.now() + DAYTOSEC + "";
    window.localStorage.setItem(POPUP, expire);

    setPopup({});
  };

  const getPopUp = async () => {
    const expire = window.localStorage.getItem(POPUP)!;

    console.log(+expire);
    console.log(Date.now());
    console.log(+expire - Date.now());

    if (+expire > Date.now()) return;
    window.localStorage.removeItem(POPUP);

    const data = {
      img: "https://api1.indj.club/api/v3/channel/image/ad_home/17a338474bee3fb8a0a960f537415589.png",
    };
    setPopup(data);
  };

  useEffect(() => {
    // getPopUp();
  }, []);

  if (popup.img) {
    return (
      <div className="popup_back">
        <div className="popup">
          <img
            onClick={() =>
              (window.location.href =
                "https://gleam.io/TXtfh/metabrox-")
            }
            src={popup.img}
            alt=""
          />
          <div className="btns">
            <button onClick={noToday} className="popup_button">
              하루동안 보지 않기
            </button>
            <span>l</span>
            <button
              onClick={() => setPopup({})}
              className="popup_button"
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PopUp;
