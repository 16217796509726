import { useRef, useState, useEffect } from 'react';
import { selectLang, changeLang } from 'redux/lang';
import { selectImg } from 'redux/img';
import { useSelector, useDispatch } from 'react-redux';
import { langT } from 'interface';
import './roadMap.scss';
import MiddleHeader from '../util/middleHeader/middleHeader';

const Roadmap = () => {
  const [dot, setDot] = useState('');
  const [video, setvideo] = useState(false);
  const reduxLang: langT = useSelector(selectLang);
  const reduxImg: string = useSelector(selectImg);
  const startIMG = useRef<HTMLImageElement>(null);
  const back = useRef<HTMLDivElement>(null);

  const size = () => {
    const starList = startIMG.current!;
    const imgWidth = starList.clientWidth;
    const imgHeight = starList.clientHeight;
    const one = Math.round(imgWidth * 0.53);
    const two = Math.round(imgHeight * 0.8);
    const three = Math.round(imgWidth * 0.7188);
    const four = Math.round(imgHeight * 0.93);
    const coords = `${one},${two},${three},${four}`;
    setDot(coords);
  };

  const activeVideo = () => {
    setvideo((old) => !old);
  };

  const share = () => {
    const t = document.createElement('textarea');
    document.body.appendChild(t);
    t.value = 'https://www.youtube.com/watch?v=FNupNEnzfn4';
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
    alert('복사되었습니다.');
  };

  useEffect(() => {
    size();
  }, []);

  return (
    <div id="road_map" className="section_bg">
      <MiddleHeader img="road_map_title" classes="h_road" />
      <div ref={back} className="road_map_back  content">
        <img
          ref={startIMG}
          useMap="#stars"
          style={{ marginTop: '50px' }}
          src={`${reduxImg}road_map_${reduxLang.type}.png`}
          alt=""
        />
        <map name="stars">
          <area
            shape="rect"
            alt="이스터에그"
            coords={dot}
            onClick={() => activeVideo()}
          />
        </map>
      </div>
      {video && (
        <div className="video">
          <div className="buttons">
            <button onClick={() => share()} className="share">
              <img className="button_img" src={`${reduxImg}share.png`} alt="" />
            </button>
            <button onClick={() => activeVideo()} className="close">
              <img className="button_img" src={`${reduxImg}close.png`} alt="" />
            </button>
          </div>
          <iframe
            title="adfsafsd"
            width={back.current?.clientWidth}
            height="315"
            src="//www.youtube.com/embed/FNupNEnzfn4"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Roadmap;
