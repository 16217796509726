import { configureStore } from "@reduxjs/toolkit";
import lang from "./lang";
import img from "./img";

export default configureStore({
  reducer: {
    lang: lang,
    img: img,
  },
});
