import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { selectLang, changeLang } from 'redux/lang';
import { selectImg } from 'redux/img';
import { useSelector, useDispatch } from 'react-redux';
import { langT } from 'interface';
import './header.scss';
import DropDown from './dropdown/dropdown';

const Header = ({ move }: any) => {
  const reduxImg: string = useSelector(selectImg);
  const reduxLang: langT = useSelector(selectLang);

  const whitePaperLink = () =>
    `https://metabrox.gitbook.io/whitepaper_${reduxLang.type}/`;

  const [SNSLIST] = useState([
    { name: 'Telegram', link: 'https://t.me/metabrox' },
    { name: 'Twitter', link: 'https://twitter.com/meta_brox' },
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/people/Meta-Brox/100078555420104/',
    },
    {
      name: 'Medium',
      link: 'https://medium.com/@metabrox/',
    },
    {
      name: 'Whitepaper',
      link: whitePaperLink(),
    },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useParams();

  const ChangeLang = (lang: any) => dispatch(changeLang(lang));

  const changingLang = () => {
    ChangeLang(lang);
  };

  const LOGO: any = useRef(null);

  useEffect(() => {
    changingLang();
  }, [lang]);

  const langMenu = [
    <button onClick={() => navigate('/kr')}>한국어</button>,
    <button onClick={() => navigate('/en')}>English</button>,
  ];

  const snsMenu = SNSLIST.map((sns: any, idx: number) => (
    <button
      onClick={() => window.open(sns.link)}
      className="basic-flex"
      key={idx}
    >
      <img className="sns_at" src={`${reduxImg}${sns?.name}.png`} alt="" />
      {sns.name}
    </button>
  ));

  return (
    <div id="header" className="header basic-flex">
      <div className="content basic-flex">
        <img
          ref={LOGO}
          onClick={() => move()}
          className="white_logo"
          src={`${reduxImg}white_logo.png`}
          alt=""
        />
        <div className="drop_down_wrap basic-flex">
          <div className="drop_down basic-flex">
            <DropDown title="sns" menu={snsMenu} />
          </div>
          <div className="drop_down basic-flex">
            <DropDown title="lang" menu={langMenu} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
