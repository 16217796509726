import { selectImg } from 'redux/img';
import { selectLang } from 'redux/lang';
import { useSelector } from 'react-redux';
import './whatToExpect.scss';
import Section from './sectionList/sectionList';
import {
  whatToExpectT,
  storyT,
  whatToTokenT,
  exceptionT,
  whatElseT,
} from 'interface';

const WhatToExpect = () => {
  const reduxImg: string = useSelector(selectImg);
  const reduxLang: any = useSelector(selectLang);

  const story: storyT[] = reduxLang.story;
  const whatToExpect: whatToExpectT[][] = reduxLang.whatToExpect;
  const whatToToken: whatToTokenT = reduxLang.whatToToken;
  const whatElse: whatElseT = reduxLang.whatElse;
  const whatBuy: any = reduxLang.whatBuy;
  const exception: exceptionT[] = reduxLang.exception;
  console.log(story);
  console.log(whatToExpect);
  console.log(whatToExpect[0][2]);

  return (
    <>
      <div id="Allstory">
        <div className="storyblop">
          <h2 className="story">
            <span>STORY </span>
            <span> LET'S BLOP</span>
          </h2>
        </div>
        <div className="section_tablet">
          <div className="section_tablet_ship">
            <img
              className="minmaxImg"
              src={`${process.env.REACT_APP_LOCAL_IMG}ship.png`}
              alt=""
            />
          </div>
          <div className="story_desc mx-5 border_bottom">
            {story.map((el, idx) => (
              <div className="story_desc_node">
                <h3 className="story_title">
                  #{idx + 1}. {el.title}
                </h3>
                <p className="story_con">{el.con}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="whatToExpect" className="content">
        {/* <MiddleHeader img="what_to_expect" classes="h_what" /> */}

        {/* {whatToToken.map((el: any, idx: number) => (
          <div
            key={idx}
            className={`section_node ${
              idx !== whatToToken.length - 1 && "border_bottom"
            }`}
          >
            {el.map((_el: any, _idx: number) => (
              <Section rtl={idx % 2 !== 0} key={_idx} data={_el} idx={_idx} />
            ))}
          </div>
        ))} */}

        <div className={`section_node false`}>
          <div className={`section rtl`}>
            <img
              className={`section_img main`}
              src={`${reduxImg}${whatToToken.img}.png`}
              alt=""
            />
            <div className="desc" style={{ marginTop: '40px' }}>
              <h2 className={`section_title bold main`}>{whatToToken.title}</h2>
              <p className="section_desc regular">{whatToToken.desc}</p>
            </div>
          </div>
        </div>

        <div className="token">
          <img className="token__under" src="/img/tokenunder.png"></img>
        </div>

        {whatToExpect.map((el: any, idx: number) => (
          <div
            key={idx}
            className={`section_node ${idx !== 3 && 'border_bottom'}`}
          >
            {el.map((_el: any, _idx: number) => (
              <Section rtl={idx % 2 !== 0} key={_idx} data={_el} idx={_idx} />
            ))}
          </div>
        ))}

        <div
          style={{ paddingTop: '0' }}
          className={`section_node border_bottom`}
        >
          <div className={`section rtl`}>
            <img
              style={{ marginTop: 0 }}
              className={`section_img sub main`}
              src={`${reduxImg}${whatBuy.img}.png`}
              alt=""
            />
            <div className="desc">
              <h2
                style={{ fontSize: '20px' }}
                className={`section_title bold main`}
              >
                {whatBuy.title}
              </h2>
              <p className="section_desc regular">
                {whatBuy.desc.map((el: any, idx: number) => {
                  if (idx % 2 === 0) {
                    return (
                      <div
                        style={{
                          fontFamily: 'Pretendard-Regular',
                          color: '#9D99B8',
                          fontWeight: 700,
                        }}
                      >
                        {el}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          fontFamily: 'Pretendard-Regular',
                          color: '#9D99B8',
                        }}
                      >
                        {el}
                      </div>
                    );
                  }
                })}
              </p>
            </div>
          </div>
        </div>

        <div className="exception">
          <span className="exception__text">{whatElse.desc}</span>
        </div>
        <div className="exception_desc">
          {exception.map((el, idx) => (
            <div className="exception_desc_node">
              <span className="exception_title">{el.title}</span>
              <p className="exception_con">{el.con}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WhatToExpect;
