const data = {
  kr: {
    type: "kr",
    story: [
      {
        title: "우주를 가로질러",
        con: `지직.. 지지직.. 아아. 여기는 좌표 BLP-1030. 무사히 도착했다. 다른 생명체는 존재하지 않는다. 그리고 새로운 우주 자원을 찾은 것 같다. 연구진들의 말로는 정말 엄청난 에너지라고 하는데. 쳇, 나도 궁금하군. 아무튼 우리는 그걸 ‘브록스’라고 부르기로 했다.`,
      },
      {
        title: "시작된 변화",
        con: `“지지직.. 큰일이다. 브록스에는 엄청난 부작용이 있었다. 강한 중독성! 게다가…이봐, 조심해! 헉, 헉. 브록스에 중독된 대원들이 모두 블롭이 돼버렸다. 말도 통하지 않는다. 블라블라, 아주 깜찍한 소리를 내며 하루 종일 음악을 먹어치운다. 젠장! 난 어떡하지. 며칠 전에도 못 참고 브록스에 손을 댔단 말이야!” `,
      },
      {
        title: "뜻밖의 귀환",
        con: `음악, 음악, 음악! 음악이 필요해! 그래! 지구, 지구로 가자! 지구엔 맛있는 음악이 가득 있어! 지구인들에게 음악을 뺏자!

좌표 EARTH-2202, 귀환을 시작합니다.
발사 준비 3, 2, 1...`,
      },
      {
        title: "“지구를 침략하러 왔어!”",
        con: `오래전, 새로운 자원을 찾기 위해 떠났던 탐사선이 귀환했습니다. 탐사선의 문이 열리자 환호하던 사람들은 경악할 수밖에 없었어요. 더 이상 그들이 기억하던 대원들은 없었거든요. 밖으로 걸어 나온 대원들이 말했습니다. 

“블라블라!”`,
      },
    ],
    whatToToken: {
      // 이미지 바꿔야함
      img: "planet",
      title: "METABX 생태계",
      desc: `글로벌 음악 콘텐츠 시장에서 아티스트와 팬덤을 연결 아티스트를 지원과 후원, 아티스트의 성장을 돕는 토큰입니다.

음악 저작권 거래, 아티스트 NFT Land 구매, 팬아트 구매, 커뮤니티 DAO 투표, 아티스트를 위한 펀딩 투표에 활용됩니다`,
    },

    whatToExpect: [
      [
        {
          img: "phone_gift",
          title: "inDJ",
          desc: "상황·감정 기반 AI 음악 플랫폼 입니다",
        },
        {
          img: "coin",
          title: "inDJ in METABX",
          desc: `내가 좋아하는 아티스트의 음악으로 소통하고 응원할 수 있어요. 내가 좋아하는 스테이션을 만들고 METABX로 보상받아요.`,
        },
        {
          img: "pink_gift",
          title: "듣기만 해도 선물・후원・보상",
          desc: `Listener는 스테이션을 듣고 DJ에게 선물과 후원을 해요. DJ는 스테이션을 만들고, 소유하고, 수익을 창출할 수 있어요.`,
        },
        {
          img: "artist",
          title: "아티스트와 소통",
          desc: "내 최애 아티스트의 콘텐츠가 매일 업데이트되고 아티스트를 위한 후원으로 METABX를 활용할 수 있어요.",
        },
      ],
      // 노트북
      [
        {
          img: "fandom_lands",
          title: "Fandom Lands",
          desc: `아티스트와 팬덤을 위한 메타버스 덕질 놀이터입니다. Exhibition Space에서는 나만의 공간에서 내 팬아트, NFT 아이템을 전시하고 덕질을 자랑할 수 있습니다.`,
        },
        {
          img: "fandom_space",
          title: "덕질 전시 공간",
          desc: `나만의 공간 Exhibition Space에서 NFT Housing을 할 수 있습니다.`,
        },
        {
          img: "fandom_community",
          title: "팬덤 커뮤니티",
          desc: `METABX로 팬덤커뮤니티를 확장하기 위해 임대료를 지불하거나 펀딩, 투표에 활용 및 보상됩니다.`,
        },
        {
          img: "fandom_center",
          title: "E-Commerce",
          desc: `Fandom에 사용되는 에셋을 팬덤 사용자가 직접 만들고 등록, 거래, 수익화가 가능합니다.`,
        },
      ],
      [
        {
          img: "asset_notebook",
          title: "애셋 만들기",
          desc: `메타버스 Fandom Lands 공간 내에서 코딩 없이 아바타, 아이템, 게임을 제작하고 나의 에셋으로 등록할 수 있어요.`,
        },
        {
          img: "asset_avatar",
          title: "아바타",
          desc: `나만의 아바타를 만들고 판매할 수 있어요. `,
        },
        {
          img: "game",
          title: "메이커",
          desc: `게임메이커를 통해서 리듬게임, 노래방, 손가락 DDR, 드럼, 피아노 등등 음악관련 게임을 만들고 METABX로 판매하고 메타버스에서 활용합니다.`,
        },
        {
          img: "change",
          title: "NFT로 관리",
          desc: `창작물을 NFT로 관리하고 실물과 연동해 굿즈를 생산 하거나 판매하고 수익화 할 수 있습니다.`,
        },
      ],
      [
        {
          img: "desktop",
          title: "NFT 마켓플레이스",
          desc: `한 자리에서 전 세계의 NFT를 확인하고 METABX로 구매하여 소유권을 가집니다.`,
        },
        {
          img: "kart",
          title: "마켓플레이스",
          desc: `전세계 크리에이터들의 에셋, 음악 저작권, NFT 검색가격비교, 유사 작품 추천, 적정가격 제안, 이종간 거래를 지원합니다.`,
        },
        {
          img: "blob",
          title: "저작권 침해 여부를 판명",
          desc: `타인의 저작권 여부를 침해하는지 확인하고 NFT로 등록·판매 할 수 있습니다.`,
        },
      ],
    ],

    whatBuy: {
      img: "metabx",
      title: "METABX로 무엇을 구매하나요?",
      desc: [
        "Fandom Lands에서 사용 가능한 Items:",
        "NFT Lands, 공간 관련 아이템, 캐릭터 강화",
        " ",
        "",
        "콘텐츠 IP구매:",
        "음악 저작권, 가상 콘서트 입장권, 게임 참여권",
        " ",
        "",
        "덕질:",
        "팬아트, DJ MiX",
      ],
    },

    exception: [
      {
        title: "Purchase",
        con: `월이용권, 투표권, NFT관련 상품, K-POP관련 상품`,
      },
      {
        title: "Trade",
        con: `Lands, BLOP'S`,
      },
      {
        title: "Create",
        con: `Music, Assets, Funding&DAO`,
      },
      {
        title: "Governance",
        con: `팬커뮤니티 DAO, 투표권`,
      },
      {
        title: "보상시스템",
        con: `출석체트, 친구초대, 영상 업로드, 월 이용권 구매 등의 활동에 보상 지급`,
      },
    ],
    whatElse: {
      desc: "그 외 무엇을 할 수 있나요?",
    },
    footer: {
      information: [
        `상호명 ${process.env.REACT_APP_COMPANY_UPPER} PTE. LTD. | 대표 김종기`,
        ` 개인정보보호책임자 이정중`,
        ` 사업자등록번호 490-81-01351`,
      ],
      contacts: [
        `서울특별시 강남구 역삼로 169, 팁스타운2 B1층 (C호실)`,
        `3003 N 1st St, San Jose, CA 95134`,
        `help@indj.net`,
      ],
    },
  },
  en: {
    type: "en",
    story: [
      {
        title: "Across the universe",
        con: `Bzzzzz…Bzzzz... Ah-ah. This is the coordinate BLP-1030. We've arrived safely. No other life forms exist. And I think I found a new space resource. Researchers say this is a lot of energy. Psh, Gets me curious, too. Whatever, we decided to call it ‘Brox’.`,
      },
      {
        title: "The change that started",
        con: `W..We..We are in trouble. Brox had a tremendous side effect. So, addictive! Besides... Hey, be careful! Huff Huff… all the guy's addicted to Brox have become Blobs. I can't even communicate. Bla bla bla, eating up music all day with a very cute sound. Damn it! What am I gonna do? I touched Brox the other day! I couldn't stop!`,
      },
      {
        title: "Unexpected return",
        con: `Music, music, music! I need music! Yes! Earth, go to Earth! Earth delicious music alot! Take away music from people in earth!

Coordinate EARTH-2202, Initiate Return
Ready to fire, three, two, one...`,
      },
      {
        title: `I'm here to invade the Earth!`,
        con: `A long time ago, a probe that had left to find new resources returned. When the door opened, cheering people were shocked. There were no more men they remember. The crew who walked out said.

"Blah blah!”`,
      },
    ],
    whatToToken: {
      // 이미지 바꿔야함
      img: "planet",
      title: "METABX Ecosystem",
      desc: `METABX token is used to connect artist and fans, support and donate the artist, help the artist growth in the global music contents market.

Also, used for music copyright trade, community DAO vote, funding vote, purchase artist NFT Land and fan art. `,
    },
    whatToExpect: [
      [
        {
          img: "phone_gift",
          title: "inDJ",
          desc: "AI Music platform based on situation and emotion.",
        },
        {
          img: "coin",
          title: "inDJ in METABX",
          desc: `You can support and communicate with your favorite artist. Also, create a station with your loving songs and get METABX reward.`,
        },
        {
          img: "pink_gift",
          title: "Present, Donation, Reward just by listening",
          desc: `Listener listen the station and can give gifts and donation to the DJ. The DJ can create, own stations and make profit from it!`,
        },
        {
          img: "artist",
          title: "Communication with Artist",
          desc: "Your favorite artist’s contents will be updated everyday and donate for the artist using METABX.",
        },
      ],
      // 노트북
      [
        {
          img: "fandom_lands",
          title: "Fandom Lands",
          desc: `Metaverse fanboying/fangirling playground for artist and fans. Exhibition Space is your own space where you can show your fan art, NFT item and boast your fanboying/fangirling.`,
        },
        {
          img: "fandom_space",
          title: "Fanboying/Fangirling Display Space",
          desc: `You can do NFT housing in your own Exhibition Space.`,
        },
        {
          img: "fandom_community",
          title: "Fan Community",
          desc: `METABX is used to expand the fan community such as rent, funding, and voting.`,
        },
        {
          img: "fandom_center",
          title: "E-Commerce",
          desc: `Fandom user can create an asset of Registering, Trading, and even Monetizing.`,
        },
      ],
      [
        {
          img: "asset_notebook",
          title: "Create Asset",
          desc: `In the metaverse Fandom Lands you can Create Avatar, Item, Game without coding and register in your asset.`,
        },
        {
          img: "asset_avatar",
          title: "Avatar",
          desc: `Make your own avatar and even sell it.`,
        },
        {
          img: "game",
          title: "Maker",
          desc: `The Game Maker makes you create music games such as Karaoke, Finger DDR, Drum, Piano, Rhythm Game. Also, sell it with METABX and use it in the metaverse.`,
        },
        {
          img: "change",
          title: "Manage NFT",
          desc: `Manage your creations by NFT and you can connect NFT into real Goods and sell them to make earnings.`,
        },
      ],
      [
        {
          img: "desktop",
          title: "NFT Market Place",
          desc: `Check all NFT in the world in one place and buy it with METABX.`,
        },
        {
          img: "kart",
          title: "Market Place",
          desc: `Support the whole world creators asset, Music copyright, Product search, Recommend similar product, Price compare, Analyze reasonable price, Interspecies transactions.`,
        },
        {
          img: "blob",
          title: "Check infringement of copyright",
          desc: `Check infringement of copyright from other people and register and sell it by NFT.`,
        },
      ],
    ],
    whatBuy: {
      img: "metabx",
      title: "What can you purchase with METABX?",
      desc: [
        "tems usable in Fandom Lands:",
        "NFT Lands, Place related items, Character enhancement",
        " ",
        "",
        "chase Contents IP:",
        "Music copyright, Metaverse music concert ticket, Game access ticket.",
        " ",
        "",
        "Fanboying/Fangirling:",
        "Fan art, DJ MiX",
      ],
    },
    exception: [
      {
        title: "Purchase",
        con: `Monthly Membership, Vote, NFT Products, K-POP Products`,
      },
      {
        title: "Trade",
        con: `Lands, BLOP’S`,
      },
      {
        title: "Create",
        con: `Music, Assets, Funding&DAO`,
      },
      {
        title: "Governance",
        con: `Fan community DAO, Vote`,
      },
      {
        title: "Reward System",
        con: `Give reward in activity like Attendance, Invite Friends, Upload `,
      },
    ],
    whatElse: {
      desc: "What else you can do?",
    },
    footer: {
      information: [
        `Company name ${process.env.REACT_APP_COMPANY_UPPER} PTE. LTD. | Representative Jongki Kim`,
        `Personal Information Protection Officer Jeongjoong Lee`,
        `Business registration number 490-81-01351`,
      ],
      contacts: [
        `Room C, B1, Tips Town 2, 169 Yeoksam-ro, Gangnam-gu, Seoul`,
        `3003 N 1st St, San Jose, CA 95134`,
        `help@indj.net`,
      ],
    },
  },
};

export default data;
