import { useEffect } from "react";
import { Routes, Route, BrowserRouter, useParams } from "react-router-dom";
import MainPage from "pages/index/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./global.css";

function App() {
  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/:lang" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
