import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectLang } from 'redux/lang';
import { selectImg } from 'redux/img';
import { useSelector } from 'react-redux';
import { langT } from 'interface';
import './footer.scss';

const Footer = () => {
  const reduxLang: langT = useSelector(selectLang);
  const reduxImg: string = useSelector(selectImg);

  const { lang } = useParams();

  useEffect(() => {}, [lang]);

  return (
    <footer>
      <div className="content">
        <img className="logo_text" src={`${reduxImg}logo_text.png`} alt="" />
        <div>
          <div className="information">
            <h4 className="information_title">Information</h4>
            <h4 className="information_text">
              METABROX PTE. LTD. | 대표 김종기 | 개인정보보호책임자 이정중 |
              사업자등록번호 490-81-01351
            </h4>
          </div>
          <div className="contacts">
            <h4 className="contacts_title">Contacts</h4>
            <h4 className="contacts_text">
              서울특별시 강남구 역삼로 169, 팁스타운2 B1층 (C호실) / 3003 N 1st
              St, San Jose, CA 95134 help@indj.net
            </h4>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
