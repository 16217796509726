import { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { selectLang, changeLang } from "redux/lang";
import { selectImg } from "redux/img";
import { useSelector, useDispatch } from "react-redux";
import { langT } from "interface";
import Header from "./components/header/header";
import "./index.scss";
import Footer from "./components/footer/footer";
import TopPlanet from "./components/topPlanet/topPlanet";
import WhatToExpect from "./components/whatToExpect/whatToExpect";
import Roadmap from "./components/roadMap/roadMap";
import FAQ from "./components/FAQ/FAQ";
import PopUp from "./components/popup/popoup";

const Index = () => {
  const reduxLang: langT = useSelector(selectLang);
  const reduxImg: string = useSelector(selectImg);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useParams();

  const about = useRef(null);
  const Team = useRef(null);
  const WhitePaper = useRef(null);
  const ChangeLang = (lang: any) => dispatch(changeLang(lang));

  const changingLang = () => {
    ChangeLang(lang);
  };

  const TOP: any = useRef(null);
  const move = () => {
    TOP.current?.scrollIntoView({ behavior: "smooth" });
  };

  const link = () => {
    if (lang === "en") {
      navigate("/kr");
    } else {
      navigate("/en");
    }
  };

  useEffect(() => {
    changingLang();
  }, [lang]);

  return (
    <div id="wrap" className="warp">
      <PopUp />
      <Header move={move} />
      <div ref={TOP}></div>
      <TopPlanet />
      <WhatToExpect />
      <Roadmap />
      <FAQ />
      <Footer />
    </div>
  );
};

export default Index;
