import { createSlice } from "@reduxjs/toolkit";
import data from "./contents";
import { langT } from "../interface";

export const langSlice = createSlice({
  name: "lang",
  initialState: {
    lang: data.kr,
  },
  reducers: {
    changeLang: (state: any, action: any) => {
      if (action.payload === "en") {
        state.lang = data.en;
      } else {
        state.lang = data.kr;
      }
    },
  },
});

export const { changeLang } = langSlice.actions;
export default langSlice.reducer;
export const selectLang = (state: any) => state.lang.lang;
