import { selectImg } from 'redux/img';
import { useSelector } from 'react-redux';
import './sectionList.scss';

const Section = ({
  data,
  idx,
  rtl,
}: {
  data: any;
  idx: number;
  rtl: boolean;
}) => {
  const reduxImg: string = useSelector(selectImg);

  return (
    <div className={`section ${rtl && 'rtl'}`}>
      <img
        className={`${data.img} section_img ${idx !== 0 ? 'sub' : 'main'} `}
        src={`${reduxImg}${data.img}.png`}
        alt=""
      />
      <div className="desc">
        <h2 className={`section_title bold ${idx === 0 && 'main'} `}>
          {data.title}
        </h2>
        <p className="section_desc regular">{data.desc}</p>
        {data.img === 'pink_gift' ? (
          <div className="section_link">
            <img
              src={`${process.env.REACT_APP_LOCAL_IMG}apple.png`}
              alt=""
              className="section_link_apple"
              onClick={() =>
                window.open(
                  'https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512',
                )
              }
            />
            <img
              src={`${process.env.REACT_APP_LOCAL_IMG}google_play.png`}
              className="section_link_google"
              alt=""
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.indj.music',
                )
              }
            />
            <img
              src={`${process.env.REACT_APP_LOCAL_IMG}Media.png`}
              alt=""
              className="section_link_media"
              onClick={() => window.open('https://indj.ai/')}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Section;
