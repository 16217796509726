import { useEffect, useRef, useState } from "react";
import { selectLang, changeLang } from "redux/lang";
import { selectImg } from "redux/img";
import { useSelector, useDispatch } from "react-redux";
import { langT } from "interface";
import "./FAQ.scss";
import MiddleHeader from "../util/middleHeader/middleHeader";
import Collapes from "./collapse/collapse";

const FAQ = () => {
  const [FAQ, setFAQ] = useState({
    Q: [<p>MetaBrox란 무엇인가요?</p>],
    A: [<p> 한글 깃북 영문 깃북</p>],
  });
  const reduxLang: langT = useSelector(selectLang);
  const reduxImg: string = useSelector(selectImg);

  useEffect(() => {
    if (reduxLang.type === "kr") {
      setFAQ(FAQ_KR);
    } else {
      setFAQ(FAQ_EN);
    }
  }, [reduxLang.type]);

  const FAQ_KR = {
    Q: [
      <p>MetaBrox란 무엇인가요?</p>,
      <p>inDJ App이 궁금해요</p>,
      <p>METABX는 어떻게 사용되나요?</p>,
      <p>METABX는 어떻게 얻나요?</p>,
      <p>에셋은 어떻게 만드나요?</p>,
      <p>NFT 아바타는 어디에서 살수 있나요?</p>,
      <p>백서는 어디에서 볼 수 있나요?</p>,
    ],

    A: [
      <p>
        K-POP의 성장과 MZ세대 새로운 트렌드에 걸맞는 음악으로 듣고, 놀고,
        소통하는 가상세계입니다. 사용자가 음악을 탐험하고, 사람들을 모아 함께
        취향을 공유하고, 경제 활동을 하는 공간이에요.
      </p>,
      <p>
        애플 다운로드 안드로이드 다운로드 웹플레이어 내가 좋아하는 곡으로 라디오
        스테이션을 만들고 사람들에게 공유하며 보상받을 수 있는 상황·감정기반 AI
        음악 플랫폼 입니다.
        <div style={{ marginTop: "10px" }} className="start-flex">
          <img
            className="icon"
            onClick={() =>
              window.open(
                "https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512"
              )
            }
            src={`${reduxImg}apple.png`}
            alt=""
          />
          <img
            className="icon"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.indj.music"
              )
            }
            src={`${reduxImg}google_play.png`}
            alt=""
          />
          <img
            className="icon"
            onClick={() => window.open("https://indj.ai/")}
            src={`${reduxImg}Media.png`}
            alt=""
          />
        </div>
      </p>,
      <p>
        MetaBrox 서비스에서 사용되는 기축통화로 경제 활동을 하는 다양한 곳에서
        활용됩니다. NFT Lands 구매, 독점 콘텐츠 열람권, NFT 구입, K-POP 아티스트
        투표, 서포트 등 콘텐츠를 이용하거나 MetaBrox 에서 경제활동을 하는데
        사용되는 기본 유틸리티 토큰이에요
      </p>,
      <p>
        NFT 아이템 가치 상승에 따라, 팬덤 커뮤니티에서 LAND 크기 및 가치에 따라
        보상됩니다. Clubbing 참여에 따른 보상도 있습니다. 보상 경제 생태계로
        참여와 경쟁을 촉진하고 팬덤과 아티스트에게는 공정한 보상을 제공하며
        플랫폼자산과 MEATBX 그리고 NFT의 가치는 올라갑니다.
      </p>,
      //아이템 무엇인가요?
      <p>
        MetaBrox 에서 제공하는 스튜디오와 게임메이커를 이용하여 만들수 있습니다.
      </p>,
      <p>
        조만간 오픈씨에서 공개, 공개시 Give away 이벤트 실시할 예정입니다.
        자세한 일정은 트위터와 텔레그램에 공지 예정이니 항상 관심을 가져주세요.
      </p>,
      <p>
        <button
          style={{ textDecoration: "underline" }}
          onClick={() =>
            window.open("https://metabrox.gitbook.io/whitepaper_kr/")
          }
        >
          한글 깃북
        </button>
        <br />
        <button
          style={{ textDecoration: "underline" }}
          onClick={() =>
            window.open("https://metabrox.gitbook.io/whitepaper_en/")
          }
        >
          영문 깃북
        </button>
      </p>,
    ],
  };
  const FAQ_EN = {
    Q: [
      <p>What is MetaBrox?</p>,
      <p>Curious about inDJ App?</p>,
      <p>How is METABX used?</p>,
      <p>How to earn METABX?</p>,
      <p>How to create an asset?</p>,
      <p>Where can I buy NFT Avatars?</p>,
      <p>Where is the project’s white paper?</p>,
    ],

    A: [
      <p>
        Decentralize universe in virtual world that users can explore music,
        gather users and share their taste and do economic activity.
      </p>,
      <p>
        AI Music App that users can make a station with your loving songs, share
        the station and even receive reward.
        <div style={{ marginTop: "10px" }} className="start-flex">
          <img
            className="icon"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.indj.music"
              )
            }
            src={`${reduxImg}google_play.png`}
            alt=""
          />
          <img
            className="icon"
            onClick={() =>
              window.open(
                "https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512"
              )
            }
            src={`${reduxImg}apple.png`}
            alt=""
          />
          <img
            className="icon"
            onClick={() => window.open("https://indj.ai/")}
            src={`${reduxImg}Media.png`}
            alt=""
          />
        </div>
      </p>,
      <p>
        Basic utility token that is used in economic activity on MetaBrox such
        as exclusive content access pass, buy NFT, vote K-POP artist, support
        and various contents.
      </p>,
      <p>
        - Reward of contributing in community activation, contents production
        activity in MetaBorx &gt; MetaBrox and inDJ App
        <br />- Earn from buy and sell NFT or share an asset and content that is
        made
      </p>,

      <p> Use the Studio or Game Maker that is provide in MetaBrox.</p>,
      <p>
        - Coming soon on Opensea. When it is released, a giveaway event is
        <br />
        scheduled. - The details will be posted on Twitter and Telegram
      </p>,

      <p>
        <button
          style={{ textDecoration: "underline" }}
          onClick={() =>
            window.open("https://metabrox.gitbook.io/whitepaper_kr/")
          }
        >
          Korean White paper
        </button>
        <br />
        <button
          style={{ textDecoration: "underline" }}
          onClick={() =>
            window.open("https://metabrox.gitbook.io/whitepaper_en/")
          }
        >
          English White paper
        </button>
      </p>,
    ],
  };

  return (
    <div id="FAQ" className="content">
      <MiddleHeader img="FAQ" classes="faq_title" />
      <div className="collapes_wrap">
        {FAQ.Q.map((el: any, idx: any) => {
          return <Collapes data={el} key={idx} Q={el} A={FAQ.A[idx]} />;
        })}
      </div>
    </div>
  );
};

export default FAQ;
