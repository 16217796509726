import { selectImg } from 'redux/img';
import { useSelector } from 'react-redux';
import './topPlanet.scss';

const TopPlanet = () => {
  const reduxImg: string = useSelector(selectImg);

  return (
    <div className="topPlanet_padding">
      <div id="topPlanet">
        <img className="topPlanet" src={`${reduxImg}top_planet2.png`} alt="" />
        <img className="bubble1 bubble" src={`${reduxImg}bubble1.png`} alt="" />
        <img className="bubble2 bubble" src={`${reduxImg}bubble2.png`} alt="" />
        <img className="bubble3 bubble" src={`${reduxImg}bubble3.png`} alt="" />
        <img className="bubble4 bubble" src={`${reduxImg}bubble4.png`} alt="" />
        <img className="bubble5 bubble" src={`${reduxImg}bubble5.png`} alt="" />
      </div>
    </div>
  );
};

export default TopPlanet;
