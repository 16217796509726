import { selectImg } from 'redux/img';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const Collapes = ({ data, Q, A }: { data: any; Q: any; A: any }) => {
  const [open, setOpen] = useState(false);

  const reduxImg: string = useSelector(selectImg);

  const wide = () => {
    setOpen((old) => !old);
  };

  return (
    <div id="collapes">
      <div className={`faq_node ${open && 'active'}`} onClick={() => wide()}>
        <div className="q basic-flex">
          <p className="Q">Q</p>
          <h3 className="q_title">{Q}</h3>
          <img
            className="arrow_down"
            src={`${reduxImg}arrow_down.png`}
            alt=""
          />
        </div>
        <div className="a">{open && A}</div>
      </div>
      <div></div>
    </div>
  );
};

export default Collapes;
